<template>
    <div class="asset-detail__layout">
        <div class="divider divider--40"></div>

        <back-to-link />
        <!-- <img
            src="~Images/Ylands-PolyBackground-skyblue-02.png"
            alt="Poly Background"
            class="asset-item__background"
        /> -->

        <div v-if="showBlockedWarning" class="asset-notification-page">
            <div class="asset-notification-title">
                This creation was blocked
            </div>
        </div>
        <div v-if="showNotFoundWarning" class="asset-notification-page">
            <div class="asset-notification-title not-found-title">
                Welp!, nothing to do here...
            </div>
            <div class="asset-notification-title">
                Got yourself in the wrong page? You can return to our
                <a href="/">homepage</a>.
            </div>
        </div>
        <div v-if="blocked" class="asset-notifications">
            <div v-if="blocked" class="asset-notification-blocked">
                <div class="asset-notification-title">
                    This asset was blocked
                    <span
                        class="asset-notification-block-time"
                        :title="getBlockedDateTitle()"
                        >{{ getBlockedDate() }}</span
                    >
                </div>
                <div class="asset-notification-footer">
                    Please contact our
                    <a
                        href="https://support.bistudio.com/ylands"
                        target="_blank"
                        >support</a
                    >
                </div>
            </div>
        </div>
        <div v-if="!showBlockedWarning && !showNotFoundWarning">
            <div>
                <AssetDetail :asset="asset" :loading="loading" />
            </div>
        </div>
    </div>
</template>

<script>
import AssetDetail from '@/components/assetDetail/AssetDetail.vue';
import EventBus from '@/EventBus';
import DateTimeUtil from '@/lib/dateTimeUtil';
import AssetsResource from '@/services/creation';
import debug from 'debug';
import { ASSET_BLOCKED } from '../lib/apiCodes';

const log = debug('workshop:pages:asset');

export default {
    name: 'AssetDetailsPage',
    components: {
        AssetDetail,
    },
    data() {
        return {
            loading: true,
            asset: {
                name: '',
                username: '',
            },
            blocked: false,
            blockedAt: null,
            blockedMessage: null,
            showBlockedWarning: false,
            showNotFoundWarning: false,
        };
    },
    created: function () {
        this.loadAsset();
        EventBus.$on('reloadAsset', () => {
            this.loadAsset();
        });
    },
    methods: {
        onAssetLoaded(model) {
            this.blocked = model.blocked;
            this.blockedAt = new Date(Date.parse(model.blocked_at));
            this.blockedMessage = model.admin_message;
        },
        getBlockedDateTitle() {
            return DateTimeUtil.getFullDateTime(this.blockedAt);
        },
        getBlockedDate() {
            return DateTimeUtil.getHumanReadableDiffString(this.blockedAt);
        },
        async loadAsset() {
            let response;
            try {
                response = await AssetsResource.get({
                    id: this.$route.params.id,
                });
            } catch (err) {
                log('Unable to load asset:', err);
                if (err.apiCode === ASSET_BLOCKED) {
                    log('Asset is blocked');
                    this.showBlockedWarning = true;
                    return;
                }

                this.showNotFoundWarning = true;
                return;
            }
            this.loading = false;
            this.asset = response.data;
            this.onAssetLoaded(this.asset);
        },
    },
};
</script>
<style lang="scss" scoped>
.asset-notification-page {
    padding-top: 10em;
    padding-bottom: 10em;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
}

.not-found-title {
    font-size: 1.5em;
    margin-bottom: 1em;
    z-index: 9999;
}

.asset-notifications {
    width: 100%;
    z-index: 9999;

    .asset-notification-blocked {
        width: 30rem;
        margin-left: auto;
        margin-right: auto;
        background-color: #ffdfdf;
    }

    .asset-notification-block-time {
        font-style: italic;
    }

    .asset-notification-title {
        border-bottom: 1px solid #ffc7c7;
        padding: 0.5rem;
    }

    .asset-notification-body {
        margin-top: 0.3rem;
        padding: 0.5rem;
    }

    .asset-notification-reason {
        font-weight: 600;
        margin-right: 0.5em;
    }

    .asset-notification-footer {
        padding: 0.5rem;
    }
}
.asset-detail__layout {
    max-width: 1279px;
    height: auto;
    margin: 0 auto;
    @media (max-width: $lg-down) {
        margin: 0 10px;
    }

    .asset-item__background {
        position: absolute;
        left: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
        width: 100vw;
        right: 50%;
        top: 0;
        z-index: 0;
    }
}
</style>
