<template>
    <div id="watch-author">
        <button
            :title="watchTitle"
            class="button watch-author__button"
            @mouseover="assetHover = true"
            @mouseleave="assetHover = false"
            @click="toggleWatch()"
        >
            <transition name="fade" mode="out-in">
                <WatchUserIcon
                    v-if="(watched && !assetHover) || (!watched && assetHover)"
                    key="watch"
                    :width="svgWidth"
                    :height="svgHeight"
                    :class="`watch-author__button--icon--${svgFillColor}`"
                />
                <WatchUserActiveIcon
                    v-if="(!watched && !assetHover) || (watched && assetHover)"
                    key="unwatch"
                    :width="svgWidth"
                    :height="svgHeight"
                    :class="`watch-author__button--icon--${svgFillColor}`"
                />
            </transition>
            <span v-if="withText && !watched" class="watch-author__button--text"
                >Follow Author</span
            >
            <span v-if="withText && watched" class="watch-author__button--text"
                >Stop Following Author</span
            >
        </button>
    </div>
</template>

<script>
//SVG
import WatchUserIcon from 'Sprites/watch_user_active.svg?inline';
import WatchUserActiveIcon from 'Sprites/watch_user.svg?inline';

export default {
    name: 'WatchAuthorWidget',
    components: { WatchUserIcon, WatchUserActiveIcon },
    props: {
        svgWidth: { type: Number, default: 19 },
        svgHeight: { type: Number, default: 15 },
        svgFill: {
            default: 'green',
            validator: function (value) {
                return ['green', 'grey'].indexOf(value) !== -1;
            },
        },
        withText: { type: Boolean, default: false },
        watched: { type: Boolean, default: false },
        inactive: { type: Boolean, default: false },
    },
    data() {
        return { assetHover: false, watching: null };
    },
    computed: {
        watchTitle() {
            if (this.inactive) {
                return 'You need to be logged into an account to watch this author';
            } else {
                if (this.watched == true) {
                    return 'You are watching this author. Click to unwatch him.';
                } else {
                    return 'You are not watching this author. Click to watch him.';
                }
            }
        },
        svgFillColor() {
            if (this.inactive) {
                return 'grey';
            } else {
                return this.svgFill;
            }
        },
    },
    methods: {
        toggleWatch() {
            if (!this.inactive) {
                if (this.watched == false) {
                    this.watching = true;
                } else {
                    this.watching = false;
                }
                this.$emit('toggle-watch-author', this.watching);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#watch-author {
    .watch-author__button {
        outline: none;
        color: $blue-main;
        display: flex;
        align-items: center;

        &--icon {
            &--green {
                fill: #71d3b9;
            }
            &--grey {
                fill: $grey-main;
            }
        }

        &--text {
            font-family: $palanquin-font;
            text-transform: none;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.45px;
            margin-left: rem(5);
        }
    }
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity $transition-values;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
