<template>
  <div :id="containerId" class="video-wrap">
    <div class="slider__image-wrap">
      <iframe
        frameborder="0"
        width="845"
        height="476"
        :src="videoUrl"
        class="responsive-iframe"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import YoutubePlayIcon from 'Sprites/youtube.svg?inline';
import urlParser from 'js-video-url-parser';

export default {
  name: 'YoutubePlayer',
  components: { YoutubePlayIcon },
  props: ['id', 'index', 'api', 'params'],
  data() {
    return {
      player: null,
    };
  },
  computed: {
    videoUrl() {
      let data = urlParser.parse(this.url);

      if (this.params) {
        return urlParser.create({
          videoInfo: data,
          format: 'embed',
          params: {
            start: this.params,
          },
        });
      } else {
        return urlParser.create({
          videoInfo: data,
          format: 'embed',
        });
      }
    },
    url() {
      if (this.params) {
        return `https://www.youtube.com/embed/${this.id}?t=${this.params}`;
      } else {
        return `https://www.youtube.com/embed/${this.id}`;
      }
    },
    containerId() {
      return `video-${this.id}-${this.index}`; // we need index, in case of duplicate videos (it'll be duplicate id's)
    },
  },
  methods: {
    playVideo() {
      if (window.innerWidth <= 1024) {
        window.open(this.url, '_blank');
      } else {
        this.loadVideo();
      }
    },
    loadVideo() {
      const YT = window.YT;
      this.player = new YT.Player(this.containerId, {
        videoId: this.id,
        events: {
          onReady: this.onPlayerReady,
        },
      });
    },
    onPlayerReady(e) {
      e.target.playVideo();
    },
  },
};
</script>

<style scoped lang="scss">
.video-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 5rem;
    height: 5rem;
    transform: scale(0.75);
    transition: transform $transition-values;
  }

  &:hover {
    svg {
      transform: scale(1);
    }
  }
}

.slider__image-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

// vue-lazy-load plugin generating extra div, so we have to set sizes for it
.slider__image-wrap {
  width: 100%;
  height: 100%;
}
</style>
