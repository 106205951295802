<template>
    <div class="asset-detail-main">
        <AssetDetailWidget
            :show-watch-author="true"
            :show-subscription="true"
            :show-rating="true"
            :show-actions="true"
            :show-author="true"
            :show-dates="true"
            :asset="asset"
            :loading="loading"
            :allow-report-action="userLoggedIn"
            :slider-with-thumbs="true"
            @rate="onAssetRated"
            @toggle-watch-author="toggleWatchAuthor"
            @report="reportAsset"
            @toggle-favorite="toggleFavorite"
        />

        <YesNoDialog ref="yesNoDialog" />
        <InfoDialog ref="infoDialog" />
    </div>
</template>

<script>
import EventBus from '@/EventBus';

import { AssetsResource } from '@/services/assets';

import { AuthorsResource } from '@/services/authors';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
    addErrorNotification,
    addSuccesNotification,
} from '../../lib/notifications';
import AssetDetailWidget from '../AssetDetailWidget.vue';

import YesNoDialog from '../common/modal/ConfirmationDialog.vue';
import InfoDialog from '../common/modal/InfoDialog.vue';

export default {
    components: {
        AssetDetailWidget,
        YesNoDialog,
        InfoDialog,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        asset: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            showModal: false,
            modalData: {},
        };
    },
    computed: {
        ...mapGetters('user', {
            userId: 'getUserId',
        }),
        userLoggedIn() {
            return !!this.userId;
        },
    },
    methods: {
        belongsToLoggedInUser() {
            return this.asset.user_id === this.userId;
        },
        async reportAsset() {
            let confirmation = await this.$refs.yesNoDialog.init(
                'Report this asset?',
                'Thanks for helping ' +
                    'us to find assets with harmful and abusive content.<br> More information ' +
                    'about allowed content you can find <a href="https://ylands.com/eula" ' + // eslint-disable-next-line vue/script-indent
                    'target="_blank"  rel="noopener,noreferrer">here</a>',
                'Report'
            );
            if (confirmation) {
                try {
                    await AssetsResource.report(
                        {
                            id: this.asset.id,
                        },
                        {
                            revision: this.asset.revision,
                            subject: 'Asset Report',
                            description: 'N/A',
                        }
                    );
                    addSuccesNotification('Report sent successfully');
                } catch (e) {
                    if (e.apiCode === 'ylandsApiService.assetExists') {
                        addErrorNotification(
                            'You already reported this creation.'
                        );
                        return;
                    }
                    addErrorNotification('Unable to report this creation');
                    return;
                }
            }
        },
        async toggleFavorite(subscribed) {
            if (!subscribed) {
                let agree = await this.$refs.yesNoDialog.init(
                    'Remove from favorites?'
                );
                if (!agree) {
                    return;
                }
            }

            try {
                await AssetsResource.subscribe(
                    {
                        id: this.asset.id,
                    },
                    {
                        subscribed: subscribed,
                    }
                );
                Vue.set(this.asset, 'subscribed', subscribed);
                addSuccesNotification(
                    'Successfully ' +
                        (subscribed
                            ? 'added to favorites'
                            : 'removed from favorites')
                );
            } catch (e) {
                console.error(e);
                addErrorNotification(
                    'Unable to ' +
                        (subscribed
                            ? 'add to favorites'
                            : 'remove from favorites')
                );
            }
        },

        async toggleWatchAuthor(watch) {
            let agree = await this.$refs.yesNoDialog.init(
                watch ? 'Watch this author?' : 'Stop watching this author?'
            );
            if (!agree) {
                return;
            }
            try {
                await AuthorsResource.watch(
                    {
                        id: this.asset.user_id,
                    },
                    {
                        watch: watch,
                    }
                );
                Vue.set(this.asset, 'my_favorite_author', watch);
                addSuccesNotification(
                    watch
                        ? 'You are now watching this author'
                        : 'You are not watching this author anymore'
                );
            } catch (e) {
                console.error(e);
                addErrorNotification('Unable change watch status');
            }
        },
        async onAssetRated(rating) {
            try {
                await AssetsResource.rate(
                    {
                        id: this.asset.id,
                    },
                    {
                        rating: rating,
                    }
                );
                EventBus.$emit('reloadAsset');
                addSuccesNotification('Successfully rated');
            } catch (err) {
                this.$refs.infoDialog.init(
                    'Some error occurred. Please try again later'
                );
                console.error('Unable to rate asset', err);
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
