const types = {
    MODS: 1,
    COMPOSITIONS: 2,
    GAMES: 3,
    ALL: null,
};

function getContentType(content) {
    let type;
    switch (content) {
        case types.MODS:
            type = 'Mod';
            break;
        case types.COMPOSITIONS:
            type = 'Composition';
            break;
        case types.GAMES:
            type = 'Game';
            break;
        case types.ALL || null:
            type = 'All';
            break;
        default:
            type = 'Game type';
            break;
    }
    return type;
}

export { types, getContentType };
