import Vue from './../init';

const uri = 'asset';

const customActions = {
    updateScreenshots: { method: 'PUT', url: uri + '/{id}/screenshots' },
    updateThumbnail: { method: 'PUT', url: uri + '/{id}/thumbnail' },
};

export default Vue.resource(uri + '{/id}', {}, customActions);
