<template>
  <aside class="sidebar-wrap">
    <div class="sidebar">
      <div class="sidebar-content" :class="{ loading: loading }">
        <div class="sidebar-content__top">
          <h3 class="sidebar-content__heading heading heading--md">Details</h3>
          <div class="sidebar-content__tag">
            <div v-if="asset.tags" class="find-tags-wrap find-tags-wrap--small">
              <ItemsVisualizer
                v-if="asset.tags"
                :default-items="asset.tags"
                :removable="false"
                :clickable="true"
                :single="true"
              />
            </div>
          </div>
        </div>
        <div class="separator separator--blue"></div>
        <ul class="sidebar-list">
          <li class="sidebar-list__item">
            Type:
            <strong>{{ contentType() }}</strong>
          </li>
          <li class="sidebar-list__item">
            Updated:
            <strong>
              <time datetime="asset.updated_at">
                {{ getHumanReadableDiffString(new Date(asset.updated_at)) }}
              </time>
            </strong>
          </li>
          <li class="sidebar-list__item">
            Released:
            <strong
              ><time v-if="asset.released_at" datetime="asset.released_at">{{
                getHumanReadableDiffString(new Date(asset.released_at))
              }}</time>
              <span v-else>Not released</span></strong
            >
          </li>
          <li
            v-if="asset.content_type != types.COMPOSITIONS"
            class="sidebar-list__item"
          >
            Protected:
            <strong>{{ asset.protected ? 'Yes' : 'No' }}</strong>
          </li>
          <li v-if="asset.revision" class="sidebar-list__item">
            Version:
            <strong>{{ changelogNumber(asset.revision) }}</strong>
          </li>
          <li
            v-if="loggedIn && userIsAdmin"
            class="sidebar-list__item"
            :title="reviewStatus(asset.user_content_reviewed).title"
          >
            Verification status:
            <strong>{{
              reviewStatus(asset.user_content_reviewed).state
            }}</strong>
          </li>
        </ul>

        <div class="separator separator--blue"></div>

        <ul class="sidebar-list">
          <li class="sidebar-list__item">
            <FavoriteWidget
              :svg-height="20"
              :svg-width="24"
              :svg-fill="'pink'"
              :subscribed="asset.subscribed"
              :with-text="true"
              :inactive="!loggedIn || belongsToLoggedInUser"
              class="sidebar-list__item--favorite"
              @toggle-favorite="toggleFavorite"
            />
          </li>
          <li class="sidebar-list__item">
            <RatingWidget
              :svg-width="24"
              :svg-height="24"
              :svg-fill="'yellow'"
              :readonly="!loggedIn || belongsToLoggedInUser"
              :show-number-of-ratings="false"
              :rating="asset.my_rating"
              class="sidebar-list__item--rating"
              @rate="onAssetRated"
            />
          </li>
          <li class="sidebar-list__item">
            <WatchAuthorWidget
              :svg-width="24"
              :svg-height="20"
              :svg-fill="'green'"
              :with-text="true"
              :watched="asset.my_favorite_author"
              :inactive="!loggedIn || belongsToLoggedInUser"
              @toggle-watch-author="toggleWatchAuthor"
            />
          </li>
          <li v-if="loggedIn && userIsAdmin" class="sidebar-list__item">
            <ContentReviewWidget
              :svg-width="24"
              @click.native="reviewCreation()"
            />
          </li>
        </ul>

        <div
          v-if="asset.content_type != types.COMPOSITIONS"
          class="sidebar-content__play-wrapper"
        >
          <div class="separator separator--blue"></div>

          <button
            class="sidebar-content__button button button--xl button--yellow button--rounded button--full-width"
            @click="openModalOrPlay"
          >
            PLAY
          </button>
        </div>
      </div>
      <div class="sidebar__lower-row">
        <button class="asset-item__info-link button" @click="reportAsset()">
          <ReportIcon width="11" fill="#556f8b" />
          Report
        </button>
        <a
          v-if="loggedIn && belongsToLoggedInUser"
          :href="`http://create.ylands.com/asset_detail/${asset.id}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            class="button asset-item__edit-link button--yellow button--rounded"
          >
            EDIT CREATION
          </button>
        </a>
      </div>
      <div v-if="loading" class="sorting__loader loader-rounded"></div>
    </div>

    <PlayDialog ref="playDialog" />
  </aside>
</template>

<script>
import DateTimeUtil from '@/lib/dateTimeUtil.js';
import { getContentType, types } from '@/lib/contentTypes.js';

import { mapGetters } from 'vuex';

import RatingWidget from '@/components/common/RatingWidget.vue';
import FavoriteWidget from '@/components/common/FavoriteWidget.vue';
import WatchAuthorWidget from '@/components/common/WatchAuthorWidget.vue';
import ContentReviewWidget from '@/components/common/ContentReviewWidget.vue';
import ItemsVisualizer from '@/components/common/ItemsVisualizer.vue';

import { addErrorNotification } from '@/lib/notifications';

//SVG
import ReportIcon from 'Sprites/report_flag.svg?inline';
// import { throwStatement } from '@babel/types';

export default {
  components: {
    PlayDialog: () =>
      import(
        /* webpackPrefetch: true */ '@/components/common/modal/PlayDialog.vue'
      ),
    RatingWidget,
    FavoriteWidget,
    ContentReviewWidget,
    ItemsVisualizer,
    WatchAuthorWidget,
    ReportIcon,
  },
  props: {
    asset: {
      type: Object,
      default: function () {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isIos: {
      type: Boolean,
      default: false,
    },
    isOperaTouch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { focused: null, types };
  },
  computed: {
    ...mapGetters('user', {
      loggedIn: 'isLogged',
      userId: 'getUserId',
      user: 'getUser',
    }),
    belongsToLoggedInUser() {
      return this.asset.user_id === this.userId;
    },
    userIsAdmin() {
      return this.user.profile.isAdmin;
    },
  },
  created() {
    window.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  destroyed() {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        this.focused = false;
      } else {
        this.focused = true;
      }
    },
    openModalOrPlay() {
      console.log(
        'ylands://' +
          encodeURIComponent(
            `-auto:mm_nav|target=game|workshopId=8712` +
              window.location.search +
              window.location.hash
          )
      );

      if (this.isMobile || this.isIos) {
        if (this.isIos) {
          try {
            if (this.isOperaTouch) {
              addErrorNotification(
                'Play deeplink not supported on Opera Touch iOS version yet. Sorry!'
              );
            } else {
              window.location.href =
                'ylands://' +
                encodeURIComponent(
                  `-auto:mm_nav|target=game|workshopId=${this.asset.id}` +
                    window.location.search +
                    window.location.hash
                );
              setTimeout(() => {
                window.location.replace(
                  'itms-apps://apps.apple.com/app/ylands/id892740310/' +
                    window.location.search +
                    window.location.hash
                );
              }, 1);
            }
          } catch (error) {
            addErrorNotification(error.message);
          }
        }
        if (this.isMobile && !this.isIos) {
          try {
            window.location.href = `intent://-auto:mm_nav|target=game|workshopId=${this.asset.id}#Intent;scheme=ylands;package=com.bistudio.ylandsgoo;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.bistudio.ylandsgoo%26hl%3Den%26gl%3DUS;end`;
          } catch (error) {
            addErrorNotification(error.message);
          }
        }
      } else {
        this.$refs.playDialog.init('Choose your platform', this.asset.id);
      }
    },
    reviewCreation() {
      let win = window.open(
        `https://ylands-workshop-admin.webdev.bistudio.com/content-review/${this.asset.id}/${this.asset.revision}`,
        '_blank'
      );
      win.focus();
    },
    contentType() {
      return getContentType(this.asset.content_type);
    },
    changelogNumber(changelog) {
      let change = changelog.toString();
      if (change.lastIndexOf('.') >= 0) {
        return change;
      } else {
        return change.concat('.0');
      }
    },
    reviewStatus(status) {
      let contentStatus = new Object();
      switch (status) {
        case 'pending':
          contentStatus.title = 'The asset has to be reviewed by an admin.';
          contentStatus.state = 'Pending';
          break;
        case 'pending_update':
          contentStatus.title =
            'The asset has been updated and needs to be reviewed by an admin again.';
          contentStatus.state = 'Pending Update';
          break;
        case 'no_content':
          contentStatus.title = "The asset doesn't have any content for review";
          contentStatus.state = 'No Content';
          break;
        case 'approved':
          contentStatus.title =
            'The asset has been reviewed and APPROVED by an admin.';
          contentStatus.state = 'Approved';
          break;
        case 'rejected':
          contentStatus.title =
            'The asset has been reviewed and REJECTED by an admin';
          contentStatus.state = 'Rejected';
          break;
        case null:
          contentStatus.title =
            "The asset hasn't been processed by content review tool yet";
          contentStatus.state = 'Not processed';
          break;

        default:
          contentStatus.title = 'N/A';
          contentStatus.state = 'N/A';
          break;
      }
      return contentStatus;
    },
    toggleFavorite(favorite) {
      this.$emit('toggle-favorite', favorite);
    },
    toggleWatchAuthor(watch) {
      this.$emit('toggle-watch-author', watch);
    },
    getHumanReadableDiffString(date) {
      return DateTimeUtil.getHumanReadableDiffString(date);
    },
    getFullDatetimeFormatted(date) {
      return DateTimeUtil.getFullDateTime(date);
    },
    reportAsset() {
      this.$emit('report', this.asset);
    },
    onAssetRated(num) {
      this.$emit('rate', num);
      this.ratingEditMode = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar-wrap {
  padding: 0 !important;
  position: relative;
  max-width: 845px;

  @media (max-width: $lg-down) {
    width: 100%;
  }

  .sidebar {
    padding: rem(15);

    @media (min-width: $md-up) {
      position: sticky;
      top: 78px;
    }

    @media (max-width: $lg-down) {
      margin: rem(20) auto;
      padding: 0;
    }

    .sidebar-content {
      width: 326px;
      border-radius: 6px;
      border: 1px solid $blue-main;
      padding: rem(25) rem(22);

      @media (max-width: $lg-down) {
        width: 100%;
      }

      &.loading {
        opacity: 0.1;
      }

      .sidebar-content__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .sidebar-content__button {
        height: 5rem;
        margin-top: rem(28);

        // @media (max-width: $xs-down) { // fixed button, overlaps with feedback
        //     position: fixed;
        //     width: 100%;
        //     left: 0;
        //     bottom: 0;
        //     margin: 0;
        //     z-index: 5000;
        //     font-size: 20px;
        //     font-weight: 700;
        //     letter-spacing: 1.33px;
        //     text-transform: uppercase;
        //     height: 4.5rem;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;

        //     &:before {
        //         content: url('~Sprites/play_symbol_triangle.svg');
        //         margin-right: rem(12);
        //         width: 13px;
        //     }
        // }
      }
    }

    .sidebar__lower-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .asset-item__info-link {
        all: unset;
        display: inline-block;
        border-radius: 4px;
        color: #556f8b;
        cursor: pointer;
        font-family: $palanquin-font;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.06px;
        transition: all $transition-values;
        position: relative;
        margin-top: 35px;
        padding: 10px 19px;
        background-color: #f4f5f6;

        &:hover {
          background-color: $blue-lighter;
        }
      }
      .asset-item__edit-link {
        outline: 0;
        display: inline-block;
        border-radius: 4px;
        cursor: pointer;
        font-family: $palanquin-font;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.06px;
        transition: all $transition-values;
        position: relative;
        margin-top: 35px;
        padding: 10px 19px;
      }
    }
  }
  .sidebar-list {
    @include listReset;

    position: relative;
    margin: rem(20) 0 rem(13);

    .sidebar-list__item {
      line-height: 1.8;
      color: #22354a;
      font-size: rem(18);
      font-weight: 400;
      letter-spacing: 0.06px;
      margin-bottom: 5px;

      svg {
        width: 24px;
      }

      strong {
        font-weight: 600;
      }

      button {
        display: flex;
        align-items: center;
      }
    }

    .sidebar-list__button {
      width: 100%;
      font-size: 0.9rem;
      letter-spacing: 0.05em;
      padding: 0.5em 2.5em;
    }
  }

  .sorting__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
  }
}
</style>
