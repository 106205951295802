<template>
    <div class="content-review-widget">
        <button
            title="Review Creation"
            class="button content-review-widget__button"
        >
            <ContentReviewIcon
                :width="svgWidth"
                :class="`content-review-widget__button--icon--${svgFillColor}`"
            />
            <span class="content-review-widget__button--text"
                >Review Creation</span
            >
        </button>
    </div>
</template>

<script>
//SVG
import ContentReviewIcon from 'Sprites/content_review.svg?inline';

export default {
    components: { ContentReviewIcon },
    props: {
        svgWidth: { type: Number, default: 24 },
    },
};
</script>

<style lang="scss" scoped>
.content-review-widget {
    .content-review-widget__button {
        outline: none;
        color: $blue-main;
        display: flex;
        align-items: center;

        &--icon {
            &--pink {
                fill: #ec37a6;
            }

            &--white {
                fill: #fff;
            }
            &--grey {
                fill: $grey-main;
            }
        }

        &--text {
            font-family: $palanquin-font;
            text-transform: none;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.45px;
            margin-left: rem(5);
        }
    }
}
</style>
