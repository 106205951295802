<template>
    <div class="asset-item__outer-wrapper">
        <article class="asset-item" :class="{ loading: loading }">
            <div class="asset-item__wrapper">
                <div class="asset-item__top-line">
                    <div>
                        <h2
                            :title="asset.name"
                            class="asset-item__heading heading heading--lg"
                        >
                            <div class="label">{{ asset.name }}</div>
                        </h2>

                        <div v-if="loading" class="asset-item__text-line"></div>
                        <div v-else class="asset-item__text-line">
                            <div class="author-line">
                                <router-link
                                    :to="{
                                        name: AUTHOR_PROFILE_PAGE_NAME,
                                        params: { id: asset.username },
                                    }"
                                    :title="
                                        'Click to see all creations from author ' +
                                        asset.username
                                    "
                                    class="asset-item__author"
                                >
                                    {{ asset.username }}
                                    {{
                                        asset.ylands_username
                                            ? `(${asset.ylands_username})`
                                            : null
                                    }}
                                </router-link>

                                <div
                                    v-if="asset.verified_author"
                                    title="Verified author"
                                    class="asset-item__verified"
                                >
                                    <VerifiedIcon
                                        class="asset-item__verified--icon"
                                    />

                                    <span
                                        class="icon-verified asset-item__verified--text"
                                        >(verified)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="showRating" class="asset-item__top-line-rating">
                        <RatingAndSubscribers
                            :rating="asset ? asset.rating : 0"
                            :reviews="asset ? asset.reviews : 0"
                            :subscribers="asset ? asset.subscribers_count : 0"
                            :reversed="true"
                            :show-number-of-ratings="true"
                            :hard-yellow="true"
                        />
                    </div>
                </div>

                <section class="asset-item__section">
                    <p>
                        {{
                            asset.description && asset.description.length > 0
                                ? asset.description
                                : 'No description'
                        }}
                    </p>
                </section>

                <AllInOneSlider
                    :id="'assetDetail' + asset.id"
                    :slides="assetThumbnails"
                    :with-thumbs="sliderWithThumbs"
                />

                <div class="divider--50"></div>
                <div class="separator"></div>

                <section class="asset-item__section">
                    <h3 class="asset-item__section-heading heading heading--lg">
                        Additional Details
                    </h3>

                    <div
                        v-if="asset.changelog && asset.revision"
                        class="asset-item__section"
                    >
                        <h3
                            class="asset-item__section-heading heading heading--sm"
                        >
                            Changelog: {{ changelogNumber(asset.revision) }}
                        </h3>

                        <span class="heading--sm">--</span>

                        <div class="asset-item__changelog">
                            <p>
                                {{ asset.changelog }}
                            </p>
                        </div>
                    </div>
                </section>
            </div>
            <Sidebar
                :asset="asset"
                :loading="loading"
                :is-mobile="isMobile"
                :is-ios="isIOS"
                :is-opera-touch="isOperaTouch"
                class="sidebar"
                @toggle-favorite="toggleFavorite"
                @toggle-watch-author="toggleWatchAuthor"
                @report="reportAsset"
                @rate="onAssetRated"
            />
        </article>
        <transition name="fade">
            <section v-if="!commentsExist" class="asset-item__section">
                <div class="asset-item__comments">
                    <button
                        class="button asset-item__comments-button"
                        @click="loadCommento"
                    >
                        <CommentsIcon width="15" fill="#ffffff" /> COMMENTS
                    </button>
                </div>
            </section>
        </transition>
        <div id="commento"></div>
    </div>
</template>

<script>
import { AUTHOR_PROFILE_PAGE_NAME } from '@/router/pageNameConstants';
import { mapGetters } from 'vuex';

import RatingAndSubscribers from './common/RatingAndSubscribers.vue';
import AllInOneSlider from './common/sliders/AllInOneSlider';
import Sidebar from '@/components/Sidebar.vue';

//SVGS
import VerifiedIcon from 'Sprites/verified_badge.svg?inline';
import CommentsIcon from 'Sprites/comments_icon.svg?inline';

export default {
    components: {
        RatingAndSubscribers,
        Sidebar,
        AllInOneSlider,
        VerifiedIcon,
        CommentsIcon,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        showWatchAuthor: {
            type: Boolean,
            default: true,
        },
        showSubscription: {
            type: Boolean,
            default: true,
        },
        showRating: {
            type: Boolean,
            default: true,
        },
        showActions: {
            type: Boolean,
            default: true,
        },
        showDates: {
            type: Boolean,
            default: true,
        },
        asset: {
            type: Object,
            default: function () {
                return {};
            },
        },
        sliderWithThumbs: {
            type: Boolean,
        },
    },
    data() {
        return {
            ratingEditMode: false,
            AUTHOR_PROFILE_PAGE_NAME,
            commentsExist: false,
        };
    },

    computed: {
        ...mapGetters('user', {
            loggedIn: 'isLogged',
            userId: 'getUserId',
        }),
        ...mapGetters('settings', {
            isMobile: 'isMobile',
            isIOS: 'isIOS',
            isOperaTouch: 'isOperaTouch',
        }),

        thumbnailFileName() {
            if (
                this.asset &&
                this.asset.thumbnail &&
                this.asset.thumbnail.data
            ) {
                return this.asset.thumbnail.data.name;
            }
            return 'thumbnail.jpg';
        },
        assetFileName() {
            if (
                this.asset &&
                this.asset.file_info &&
                this.asset.file_info.filename
            ) {
                return this.asset.file_info.filename;
            }
            return 'resource.ycp';
        },
        assetThumbnails() {
            let thumbs = [];
            //TODO - finish videos
            if (this.asset.videos && this.asset.videos.length > 0) {
                this.asset.videos.map((video) => {
                    if (video.file_id) {
                        return;
                    }
                    thumbs.push({
                        data: {
                            id: video.id,
                            name:
                                'https://img.youtube.com/vi/' +
                                video.id +
                                '/0.jpg',
                            type: 'video',
                            params: video.data.params
                                ? { start: video.data.params.start }
                                : null,
                        },
                    });
                });
            }
            if (this.asset.thumbnail && this.asset.thumbnail.file_id) {
                thumbs.push(this.asset.thumbnail);
            }
            if (this.asset.screenshots && this.asset.screenshots.length > 0) {
                for (let i = 0; i < this.asset.screenshots.length; i++) {
                    thumbs.push(this.asset.screenshots[i]);
                }
            }
            if (thumbs.length === 0) {
                thumbs.push('/static/img/asset_thumb_placeholder.jpg');
            }
            return thumbs;
        },
        assetTags() {
            if (this.asset) {
                return this.asset.tags.map((tag) => {
                    return tag.id;
                });
            }
            return [];
        },
        myRating() {
            if (this.asset && this.asset.my_rating && !this.ratingEditMode) {
                return this.asset.my_rating.rating;
            }
            return 0;
        },
        belongsToLoggedInUser() {
            return this.asset.user_id === this.userId;
        },
    },
    methods: {
        loadCommento() {
            if (!this.commentsExist) {
                let commentoScript = document.createElement('script');
                commentoScript.setAttribute(
                    'src',
                    'https://cdn.commento.io/js/commento.js'
                );
                commentoScript.setAttribute(
                    'data-css-override',
                    'https://play.ylands.com/css/_commento.css'
                );
                commentoScript.defer = true;
                document.head.appendChild(commentoScript);
                this.commentsExist = true;
            }
        },
        changelogNumber(changelog) {
            let change = changelog.toString();
            if (change.lastIndexOf('.') >= 0) {
                return change;
            } else {
                return change.concat('.0');
            }
        },
        getRatingLabel() {
            if (!this.asset) {
                return;
            }
            let count = 0;
            for (let i = 1; i <= 5; i++) {
                if (
                    this.asset &&
                    this.asset.rating_stats &&
                    this.asset.rating_stats[i]
                ) {
                    count += this.asset.rating_stats[i].count;
                }
            }
            if (count === 0) {
                return 'Not yet rated';
            }
            return (
                'Overall rating: ' +
                this.asset.rating +
                ' by ' +
                count +
                ' users'
            );
        },
        toggleRatingEditMode() {
            this.ratingEditMode = !this.ratingEditMode;
        },
        reportAsset(asset) {
            this.$emit('report', asset);
        },
        toggleFavorite(favorite) {
            this.$emit('toggle-favorite', favorite);
        },
        toggleWatchAuthor(watch) {
            this.$emit('toggle-watch-author', watch);
        },
        onAssetRated(num) {
            this.$emit('rate', num);
            this.ratingEditMode = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.asset-item__outer-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    heigh: auto;

    #commento {
        margin-top: 40px;
    }
}
.asset-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    heigh: auto;

    @media (max-width: $lg-down) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .asset-item__wrapper {
        z-index: 10;
        max-width: 846px;
        width: 100%;
        height: auto;
        min-width: 0;
    }
    .author-line {
        display: inline-flex;
        align-items: center;
        margin-bottom: rem(20);

        .author-name {
            flex: auto;
            word-break: break-all;
        }

        .asset-item__author {
            font-family: $palanquin-font;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0.68px;
            color: $blue-main;
        }

        .asset-item__verified {
            &--icon {
                fill: $green-main;
                width: rem(16);
                height: rem(15);
                margin-left: rem(5);
            }

            &--text {
                color: #b2b2b2;
                font-family: $palanquin-font;
                font-size: rem(16);
                font-weight: 400;
                letter-spacing: 0.55px;
                margin-left: rem(11);
            }
        }
    }
}

.asset-item__section {
    padding-bottom: 1.7rem;

    p {
        color: $blue-main;
        font-family: $palanquin-font;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.26px;
        line-height: 27px;
    }
    .asset-item__comments {
        display: flex;
        justify-content: center;

        .asset-item__comments-button {
            color: white;
            background-color: $blue-main;
            padding: 15px 40px;
            border-radius: 6px;

            &:hover {
                background-color: lighten($blue-main, 15%);
            }
        }
    }
}

//
// START
// PLACEHOLDER RELATED STYLES
//

.asset-item.loading {
    .asset-item__heading {
        width: 420px;
        height: 35px;
        background-color: $grey-light;
        color: transparent;
    }

    .asset-item__text-line {
        margin-top: 5px;
        width: 180px;
        height: 15px;
        background-color: $grey-light;
        color: transparent;
    }
}

//
// END
// PLACEHOLDER RELATED STYLES
//

.asset-item__top-line {
    display: flex;
    flex-direction: column;
    margin: rem(24) 0 rem(20);

    @media (max-width: $sm-down) {
        display: block;
    }
    .asset-item__top-line-rating {
        flex: 0 0 auto;

        @media (max-width: $sm-down) {
            margin-left: 0;
            margin-top: 1rem;
        }
    }
}

.asset-item__heading {
    margin: 0;
    max-height: 2.2em;
    overflow: hidden;

    @media (max-width: $sm-down) {
        margin: rem(10) 0;
    }
}
</style>
