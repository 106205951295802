<template>
    <div v-if="slides.length > 0" class="all-in-one-slider">
        <swiper
            ref="swiperTop"
            class="swiper-container gallery-top"
            :options="swiperOptionTop"
        >
            <swiper-slide
                v-for="(slide, index) in slides"
                ref="slideTop"
                :key="`topSlider_${index}`"
            >
                <div
                    v-if="slide.data && slide.data.type === 'video'"
                    class="slider__video"
                >
                    <YoutubePlayer
                        :id="slide.data.id"
                        :index="index"
                        :api="youtubeApiIsReady"
                        :params="
                            slide.data.params ? slide.data.params.start : null
                        "
                    />
                </div>

                <a
                    v-else-if="slide.data && slide.data.name"
                    class="slider__link"
                    :href="setImgSrc(slide)"
                >
                    <div class="slider__image-wrap">
                        <img
                            loading="lazy"
                            width="845"
                            height="476"
                            :src="setImgSrc(slide)"
                        />
                    </div>
                </a>

                <a v-else :href="slide" class="slider__link">
                    <div class="slider__image-wrap">
                        <img
                            loading="lazy"
                            width="845"
                            height="476"
                            :src="setImgSrc(slide)"
                        />
                    </div>
                </a>
            </swiper-slide>
        </swiper>
        <!-- swiper2 Thumbs -->

        <swiper
            v-if="withThumbs"
            ref="swiperThumbs"
            class="swiper-container gallery-thumbs"
            :options="swiperOptionThumbs"
        >
            <swiper-slide
                v-for="(slide, index) in slides"
                ref="slideThumb"
                :key="`bottomSlider_${index}`"
                class="swiper-slide__thumb"
            >
                <div class="slider-asset-bottom__wrap">
                    <img
                        loading="lazy"
                        width="200"
                        height="110"
                        :src="setImgSrc(slide)"
                    />
                    <i
                        v-if="slide.data && slide.data.type === 'video'"
                        class="slider__item-icon slider__item-icon--play"
                    >
                        <YoutubePlayIcon />
                    </i>
                </div>
            </swiper-slide>
        </swiper>

        <div class="navigation">
            <div slot="button-next" class="swiper-button-next">
                <ArrowSliderIcon />
            </div>
            <div slot="button-prev" class="swiper-button-prev">
                <ArrowSliderIcon />
            </div>
        </div>
        <div
            slot="pagination"
            class="swiper-pagination swiper-pagination-bullets"
        ></div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

import { mapGetters } from 'vuex';

import YoutubePlayer from '@/components/common/YoutubePlayer';

//SVGs
import ArrowSliderIcon from 'Sprites/arrow_slider.svg?inline';
import YoutubePlayIcon from 'Sprites/youtube.svg?inline';

let resizeDebounceTimer = null;

export default {
    name: 'AllInOneSlider',
    components: {
        Swiper,
        SwiperSlide,
        ArrowSliderIcon,
        YoutubePlayIcon,
        YoutubePlayer,
    },
    props: {
        slides: { type: Array, default: () => [] },
        withThumbs: { type: Boolean, default: true },
    },
    data() {
        return {
            swiperOptionTop: {
                loop: true,
                speed: 500,
                loopedSlides: 5, // looped slides should be the same
                touchRatio: 0.2,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet(index, className) {
                        return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
                    },
                },
            },
            swiperOptionThumbs: {
                loopedSlides: 5, // looped slides should be the same
                loop: true,
                speed: 500,
                slidesPerView: 'auto',
                spaceBetween: 20,
                touchRatio: 0.2,
                slideToClickedSlide: true,
                centeredSlides: true,
                grabCursor: true,
            },

            previewWidth: null,
            youtubeApiIsReady: false,
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        ...mapGetters('settings', {
            previewSizes: 'previewSizes',
            thumbnailsUrl: 'thumbnailsUrl',
            thumbsWebpSupport: 'thumbsWebpSupport',
            webpSupports: 'webp',
            youtubeApiLoaded: 'youtubeApiLoaded',
        }),
        containVideo() {
            const slides = this.slides;
            if (!slides && slides.length) {
                return false;
            }
            let video = false;
            for (let i = 0; i < slides.length; i++) {
                if (slides[i].data && slides[i].data.type === 'video') {
                    video = true;
                    break;
                }
            }
            return video;
        },
    },
    watch: {
        containVideo(val) {
            if (val) {
                this.loadPlayer();
            }
        },
    },

    mounted() {
        if (this.containVideo) {
            this.loadPlayer();
        }
        this.previewWidth = this.getPreviewWidth(this.$el.offsetWidth);
        window.addEventListener('resize', this.handleResize);
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper;
            const swiperThumbs = this.$refs.swiperThumbs.$swiper;
            swiperTop.controller.control = swiperThumbs;
            swiperThumbs.controller.control = swiperTop;
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        handleResize() {
            let width = this.$el.offsetWidth;
            if (resizeDebounceTimer) {
                clearTimeout(resizeDebounceTimer);
            }
            resizeDebounceTimer = setTimeout(() => {
                this.previewWidth = this.getPreviewWidth(width);
                this.windowWidth = window.innerWidth;
            }, 500);
        },

        getPreviewWidth(width) {
            let previewWidth = 640;
            if (this.previewSizes && this.previewSizes.length > 0) {
                previewWidth = this.previewSizes[0];
            } else {
                return previewWidth;
            }
            for (let i = 1; i < this.previewSizes.length; i++) {
                if (previewWidth < width) {
                    previewWidth = this.previewSizes[i];
                } else {
                    break;
                }
            }
            return previewWidth;
        },
        loadPlayer() {
            // Load API only once
            if (!this.youtubeApiLoaded) {
                var tag = document.createElement('script');
                tag.id = 'ytb-api';
                tag.src = 'https://www.youtube.com/iframe_api';
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                window.onYouTubeIframeAPIReady = () => {
                    this.youtubeApiIsReady = true;
                    this.$store.commit('settings/youtubeApiLoaded', true);
                };
            } else {
                this.youtubeApiIsReady = true;
            }
        },
        setImgSrc(slide) {
            if (slide && slide.file_id) {
                const type = this.webpSuppport ? 'webp' : 'jpg';

                return `/api/images/${slide.file_id}?width=${this.previewWidth}&type=${type}`;
            }

            if (this.webpSuppport) {
                return require('Images/asset_thumb_placeholder.webp');
            } else {
                return require('Images/asset_thumb_placeholder.jpg');
            }
        },
    },
};
</script>

<style lang="scss">
// bullshit hack for fucking swiper jesus christ
.swiper-wrapper {
    height: auto !important;
}
</style>
<style lang="scss" scoped>
.all-in-one-slider {
    position: relative;

    .gallery-top {
        margin: 0 auto 35px;

        .swiper-slide {
            height: auto;
            .slider__image-wrap {
                img {
                    max-width: 100%;
                    height: auto;
                    max-height: 475px;
                    border-radius: 7px;
                }
            }
            .slider__video {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                iframe {
                    width: 100%;
                    height: 100%;
                    min-height: rem(400);

                    @media (max-width: $xs-down) {
                        min-height: rem(170);
                    }
                }
            }
        }
    }

    .gallery-thumbs {
        max-width: 629px;
        width: 100%;
        margin: 0 auto;

        @media (max-width: $lg-up) {
            display: none;
        }
        .swiper-wrapper {
            .swiper-slide {
                max-width: 196px;
                height: auto;
                border-radius: 7px;
                margin: 0 auto;

                .slider-asset-bottom__wrap {
                    height: 110px;
                    min-height: 50px;
                    border-radius: 7px;
                    object-fit: contain;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 7px;
                    }

                    .slider__item-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: none;
                        z-index: 1;

                        svg {
                            transition: none;
                        }

                        &--play {
                            svg {
                                fill: #cd201f;
                                color: #fff;
                                width: rem(42);
                                height: rem(42);
                            }
                        }
                    }
                }
            }

            .swiper-slide {
                &-active {
                    img {
                        border: 2px solid #ffa306;
                    }
                }
            }
        }
    }

    .navigation {
        width: 100%;
        position: absolute;
        display: flex;
        bottom: 87px;

        @media (min-width: 0) and (max-width: $lg-down) {
            visibility: hidden;
            display: none;
        }

        .swiper-button-prev,
        .swiper-button-next {
            cursor: pointer;
            position: absolute;
            z-index: 1;
            bottom: 0;
            border-radius: 100%;
            background-image: none;
            background-color: $yellow-main;
            width: rem(53);
            height: rem(53);
            outline: 0;
            svg {
                width: em(21);
                height: em(31);
                fill: $fake-white;
            }
            &:after {
                display: none; //hide default swiper arrows
            }

            &:hover,
            &:focus {
                svg {
                    fill: lighten(#fa9f2a, 15%);
                }
            }
        }
        .swiper-button-prev {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .swiper-pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: $lg-up) {
            visibility: hidden;
            display: none;
        }

        /deep/ .swiper-pagination-bullet-custom {
            $size: 10px;
            width: $size !important;
            height: $size !important;
            line-height: $size !important;
            text-align: center;
            opacity: 1;
            background: #dce3e9;
            transition: all $transition-values;
            margin-left: 6px;
            outline: 0;

            &:hover {
                opacity: 1;
            }

            &.swiper-pagination-bullet-active {
                opacity: 1;
                background: #556f8b;
            }
        }
    }
}
</style>
