<template>
    <Modal v-if="showModal" size="sm" @close="onClose()">
        <div slot="body" class="modal-content">
            <h2 class="heading heading--lg">{{ label }}</h2>

            <div class="divider divider--40"></div>

            <div class="text--center">
                <button
                    class="modal__button-yesno button button--with-icon active"
                    @click="onClose()"
                >
                    Ok
                    <i class="icon-finish">
                        <TickIcon />
                    </i>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from './Modal.vue';

import TickIcon from 'Sprites/tick.svg?inline';

export default {
    components: {
        Modal,
        TickIcon,
    },
    data() {
        return {
            showModal: false,
            label: '',
        };
    },
    methods: {
        onClose() {
            this.$emit('dialogClose');
        },
        init(label) {
            if (!label) {
                throw Error('label is required');
            }
            this.label = label;
            this.showModal = true;
            this.$once('dialogClose', () => {
                this.showModal = false;
            });
        },
    },
};
</script>
